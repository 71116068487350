dmx.Component('dropzone', {

    extends: 'input',

    initialData: {
        file: null,
        files: [],
        lastError: ''
    },

    attributes: {
        accept: {
            type: String,
            default: ''
        },

        required: {
            type: Boolean,
            default: false
        },

        message: {
            type: String,
            default: 'Drop files here or click to upload.'
        },

        'thumbs': {
            type: String,
            default: 'true'
        },

        'thumb-width': {
            type: Number,
            default: 100
        },

        'thumb-height': {
            type: Number,
            default: 100
        }
    },

    methods: {
        remove: function(id) {
            this.remove(id);
        },

        reset: function() {
            this.reset();
        }
    },

    render: function(node) {
        this.form = this.$node.form;
        this.idx = 0;

        if (!this.form || !this.form.dmxExtraData) {
            console.warn('Filedrop can only work on a serverconnect form!');
            return;
        }

        if (Array.isArray(this.form.dmxExtraElements)) {
            this.form.dmxExtraElements.push(this);
        }

        this.message = document.createElement('div');
        this.message.className = 'dmxDropzoneMessage';
        this.message.innerHTML = this.props.message;

        this.dropzone = document.createElement('div');
        this.dropzone.style.cssText = this.$node.style.cssText;
        this.dropzone.className = this.$node.className;
        this.dropzone.classList.add('dmxDropzone');
        this.dropzone.appendChild(this.message);

        this.dropzone.addEventListener('click', this.click.bind(this));

        this.dropzone.addEventListener('dragover', this.dragover.bind(this));
        this.dropzone.addEventListener('dragenter', this.dragenter.bind(this));
        this.dropzone.addEventListener('dragleave', this.dragleave.bind(this));
        this.dropzone.addEventListener('drop', this.drop.bind(this));

        this.$node.addEventListener('change', this.change.bind(this));
        //this.form.addEventListener('submit', this.submit.bind(this));
        this.form.addEventListener('reset', this.reset.bind(this));

        dmx.dom.replace(this.$node, this.dropzone);

        requestAnimationFrame(this.addSubmitHandler.bind(this));
    },

    addSubmitHandler: function() {
        this.form.dmxComponent.addEventListener('submit', this.submit.bind(this));
    },

    update: function(props) {
        var message = this.props.message;

        this.$node.accept = this.props.accept;

        if (this.data.files.length) {
            message += ' (' + this.data.files.length + ' files)';
        } else if (this.data.file) {
            message += ' (' + this.data.file.name + ')';
        }

        if (this.message && this.message.innerHTML != message) {
            this.message.innerHTML = message;
        }
    },

    click: function(event) {
        this.$node.click();
    },

    change: function(event) {
        this.addFiles(event.target.files);
        this.$node.value = '';
        this.$node.type = '';
        this.$node.type = 'file';
    },

    dragover: function(event) {
        // prevent default to allow drop
        event.preventDefault();
        event.stopPropagation();

        var ea;
        try { ea = event.dataTransfer.effectAllowed; } catch(e) {}

        event.dataTransfer.dropEffect = (ea == 'move' || ea == 'linkMove') ? 'move' : 'copy';
    },

    dragenter: function(event) {
        event.preventDefault();
        event.stopPropagation();
        this.dropzone.classList.add('dmxDropzoneHover');
    },

    dragleave: function(event) {
        this.dropzone.classList.remove('dmxDropzoneHover');
    },

    drop: function(event) {
        event.preventDefault();
        event.stopPropagation();
        this.dropzone.classList.remove('dmxDropzoneHover');

        if (!event.dataTransfer) return;

        var files = event.dataTransfer.files;

        if (files.length) {
            var items = event.dataTransfer.items;

            if (items && items.length && items[0].webkitGetAsEntry) {
                this.addFilesFromItems(items);
            } else {
                this.addFiles(files);
            }
        }
    },

    getValidationRules: function() {
        var rules = [];

        for (var i = 0; i < this.$node.attributes.length; i++) {
            var attribute = this.$node.attributes[i];

            if (/^data-rule-/.test(attribute.name)) {
                var rule = attribute.name.substr(10).toLowerCase();
                var message = this.$node.getAttribute('data-msg-' + rule);
                var param = attribute.value;
                rules.push({ rule: rule, param: param, message: message });
            }
        }

        return rules;
    },

    validate: function() {
        var errorMessage = this._validate();

        if (errorMessage) {
            this.setErrorMessage(errorMessage);
            this.set('invalid', true);
            this.set('validationMessage', errorMessage);
            return;
        }

        this.setErrorMessage('');
        this.set('invalid', false);
        this.set('validationMessage', '');
        
        this.updateData();
},

    _validate: function(file) {
        if (!file) {
            if (this.props.required && !this.data.files.length) {
                return this.$node.getAttribute('data-msg-required') || 'This field is required.';
            }

            return null;
        }

        if (this.props.accept) {
            if (!this.props.accept.split(/\s*,\s*/g).some(function(type) {
                if (type.charAt(0) == '.') {
                    if (file.name.match(new RegExp('\\' + type + '$', 'i'))) {
                        return true;
                    }
                } else if (/(audio|video|image)\/\*/i.test(type)) {
                    if (file.type.match(new RegExp('^' + type.replace(/\*/g, '.*') + '$', 'i'))) {
                        return true;
                    }
                } else {
                    if (file.type.toLowerCase() == type.toLowerCase()) {
                        return true;
                    }
                }

                return false;
            })) {
                return this.$node.getAttribute('data-msg-accept') || 'This file type is not allowed for upload.';
            }
        }

        var rules = this.getValidationRules();

        for (var i = 0; i < rules.length; i++) {
            var rule = rules[i].rule;
            var param = rules[i].param;
            var message = rules[i].message;

            switch (rule) {
                case 'minsize':
                    if (file.size < param) {
                        return (message || 'Please select a file of at least {0} bytes.').replace('{0}', param);
                    }
                break;

                case 'maxsize':
                    if (file.size > param) {
                        return (message || 'Please select a file of no more than {0} bytes.').replace('{0}', param);
                    }
                break;

                case 'maxtotalsize':
                    var size = 0;
                    for (var j = 0; j < this.data.files.length; j++) {
                        size += this.data.files[j].size;
                    }
                    if (size + file.size > param) {
                        return (message || 'Total size of selected files should be no more than {0} bytes.').replace('{0}', param);
                    }
                break;

                case 'maxfiles':
                    if (this.data.files.length >= param) {
                        return (message || 'Please select no more than {0} files.').replace('{0}', param);
                    }
                break;
            }
        }

        return null;
    },

    setErrorMessage: function(message) {
        var id = 'dmxValidationError' + this.form.getAttribute('id') + (this.$node.getAttribute('name') || this.$node.getAttribute('id'));
        var err = document.getElementById(id);

        if (!err) {
            err = document.createElement(dmx.bootstrap4forms ? 'div' : 'span');
            err.id = id;
            err.className = dmx.bootstrap4forms ? 'invalid-feedback' : dmx.bootstrap3forms ? 'help-block' : 'dmxValidator-error';
            this.dropzone.insertAdjacentElement('afterend', err);
        }

        if (dmx.bootstrap4forms) {
            this.dropzone.classList.add('form-control');
            if (message) {
                this.dropzone.classList.remove('is-valid');
                this.dropzone.classList.add('is-invalid');
            } else {
                this.dropzone.classList.remove('is-invalid');
                this.dropzone.classList.add('is-valid');
            }
        }

        err.textContent = message;
    },

    submit: function(event) {
        if (dmx.rules) {
            this.setErrorMessage('');

            var element = {
                type: 'file',
                files: this.$node.multiple ? this.data.files : (this.data.file ? [this.data.file] : [])
            };

            if (this.props.required && !element.files.length) {
                var message = this.$node.getAttribute('data-msg-required') || 'This field is required.';
                this.setErrorMessage(message);
                event.preventDefault();
                return false;
            }

            var rules = this.getValidationRules();

            for (var i = 0; i < rules.length; i++) {
                var rule = rules[i].rule;
                var param = rules[i].param;

                if (dmx.rules[rule]) {
                    if (!dmx.rules[rule].validity(element, param)) {
                        var message = rules[i].message || dmx.rules[rule].message;
                        if (Array.isArray(param)) {
                            message = message.replace(/\{(\d)\}/g, function(match, i) {
                                return param[i];
                            });
                        } else {
                            message = message.replace(/\{0\}/g, param);
                        }

                        this.setErrorMessage(message);

                        event.preventDefault();
                        return false;
                    }
                }
            }
        }
    },

    reset: function() {
        if (this.form.dmxExtraData[this.$node.name]) {
            if (Array.isArray(this.form.dmxExtraData[this.$node.name])) {
                this.form.dmxExtraData[this.$node.name].forEach(function(file) {
                    if (file.thumb) file.thumb.remove();
                });
            } else if (this.form.dmxExtraData[this.$node.name].thumb) {
                this.form.dmxExtraData[this.$node.name].thumb.remove();
            }
        }

        var id = 'dmxValidationError' + this.form.getAttribute('id') + (this.$node.getAttribute('name') || this.$node.getAttribute('id'));
        var err = document.getElementById(id);

        if (err && err.parentNode) {
            err.parentNode.removeChild(err);
        }

        if (dmx.bootstrap4forms) {
            this.dropzone.classList.remove('is-valid');
            this.dropzone.classList.remove('is-invalid');
        }

        delete this.form.dmxExtraData[this.$node.name];
        this.set('files', []);
        this.set('file', null);
    },

    remove: function(id, event) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        if (this.$node.multiple) {
            var index = this.data.files.findIndex(function(info) {
                return info.id == id;
            });

            if (index != -1) {
                this.data.files.splice(index, 1);
                this.form.dmxExtraData[this.$node.name][index].thumb.remove();
                this.form.dmxExtraData[this.$node.name].splice(index, 1);
                dmx.requestUpdate();
            }
        } else if (this.data.file) {
            this.data.file = null;
            this.form.dmxExtraData[this.$node.name].thumb.remove();
            delete this.form.dmxExtraData[this.$node.name];
            dmx.requestUpdate();
        }
    },

    createThumb: function(file) {
        var thumb = document.createElement('div');
        thumb.className = 'dmxDropzoneThumb';
        thumb.title = file.name;
        thumb.style.setProperty('width', (parseInt(this.props['thumb-width']) || 100) + 'px');
        thumb.style.setProperty('height', (parseInt(this.props['thumb-height']) || 100) + 'px');
        thumb.addEventListener('click', this.remove.bind(this, file.id));

        var filename = document.createElement('div');
        filename.className = 'dmxDropzoneFilename';
        filename.textContent = file.name;
        thumb.appendChild(filename);

        var filesize = document.createElement('div');
        filesize.className = 'dmxDropzoneFilesize';
        filesize.textContent = this.formatSize(file.size);
        thumb.appendChild(filesize);

        file.thumb = thumb;

        this.dropzone.appendChild(thumb);
    },

    formatSize: function(number, decimals, binary) {
        if (isNaN(number) || !isFinite(number)) return 'Invalid Size';

        decimals = decimals || 1;

        var base = binary ? 1024 : 1000;
        var suffix = binary ? ['KiB', 'MiB', 'GiB', 'TiB'] : ['KB', 'MB', 'GB', 'TB'];

        for (var i = 3; i >= 0; i--) {
            var n = Math.pow(base, i + 1);
            if (number >= n) {
                number /= n;
                var pow = Math.pow(10, decimals);
                number = Math.round(number * pow) / pow;
                return number + suffix[i];
            }
        }

        return number + 'B';
    },

    resize: function(src, cb) {
        var img = document.createElement('img');

        var tWidth = parseInt(this.props['thumb-width']) || 100;
        var tHeight = parseInt(this.props['thumb-height']) || 100;

        img.onload = function() {
            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext('2d');

            var sWidth = img.width;
            var sHeight = img.height;

            tWidth = Math.min(tWidth, sWidth);
            tHeight = Math.min(tHeight, sHeight);

            var sRatio = sWidth / sHeight;
            var tRatio = tWidth / tHeight;

            if (sWidth > tWidth || sHeight > tHeight) {
                if (sRatio > tRatio) {
                    sWidth = sHeight * tRatio;
                } else {
                    sHeight = sWidth / tRatio;
                }
            }

            canvas.width = tWidth;
            canvas.height = tHeight;

            var sx = (img.width - sWidth) / 2;
            var sy = (img.height - sHeight) / 2;

            ctx.drawImage(img, sx, sy, sWidth, sHeight, 0, 0, tWidth, tHeight);

            cb(canvas.toDataURL());
        };
        img.src = src;
    },

    addFile: function(file) {
        var errorMessage = this._validate(file);

        if (errorMessage) {
            this.setErrorMessage(errorMessage);
            return;
        }

        this.setErrorMessage('');

        if (this.$node.multiple) {
            this.form.dmxExtraData[this.$node.name] = this.form.dmxExtraData[this.$node.name] || [];
            this.form.dmxExtraData[this.$node.name].push(file);
        } else {
            this.remove();
            this.form.dmxExtraData[this.$node.name] = file;
        }

        file.id = ++this.idx

        var info = {
            id: file.id,
            date: (file.lastModified ? new Date(file.lastModified) : file.lastModifiedDate).toISOString(),
            name: file.name,
            size: file.size,
            type: file.type,
            dataUrl: null
        };

        if (this.props.thumbs != 'false') {
            this.createThumb(file);
        }

        if (file.type.indexOf('image/') !== -1 && !file.reader) {
            file.reader = new FileReader();

            file.reader.onload = function(event) {
                info.dataUrl = event.target.result;
                if (file.thumb) {
                    this.resize(info.dataUrl, function(dataUrl) {
                        file.thumb.style.setProperty('background-image', 'url(' + dataUrl + ')');
                    });
                }
                dmx.requestUpdate();
            }.bind(this);

            file.reader.readAsDataURL(file);
        }

        if (this.$node.multiple) {
            this.set('files', this.data.files.concat([info]));
        } else {
            this.set('file', info);
        }
    },

    addFiles: function(files) {
        dmx.array(files).forEach(function(file) {
            this.addFile(file);
        }, this);
    },

    addFilesFromItems: function(items) {
        dmx.array(items).forEach(function(item) {
            var entry;
            if (item.webkitGetAsEntry && (entry = item.webkitGetAsEntry())) {
                if (entry.isFile) {
                    this.addFile(item.getAsFile());
                } else if (entry.isDirectory) {
                    this.addFilesFromDirectory(entry);
                }
            } else if (item.getAsFile) {
                if (!item.kind || item.kind == 'file') {
                    this.addFile(item.getAsFile());
                }
            }
        }, this);
    },

    addFilesFromDirectory: function(directory, path) {
        var reader = directory.createReader();
        var readEntries = function() {
            reader.readEntries(function(entries) {
                if (entries.length) {
                    entries.forEach(function(entry) {
                        if (entry.isFile) {
                            entry.file(function(file) {
                                file.fullPath = path + '/' + file.name;
                                this.addFile(file);
                            }.bind(this));
                        } else if (entry.isDirectory) {
                            this.addFilesFromDirectory(entry, path + '/' + entry.name);
                        }
                    }, this);
                }

                readEntries();
            }.bind(this), function(error) {
                console.warn(error);
            }.bind(this));
        }.bind(this);

        readEntries();
    }

});
